<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>USG 4 Dimensi</h1>
            <p>
              Untuk pengecekan dengan USG 4 dimensi visual yang diperoleh makin
              detil dan persis dan dapat bergerak layaknya melihat film. USG 4D
              pun bisa menyajikan gambar anggota tubuh dengan detil, contohnya
              hidungnya mancung atau tidak. Juga kegiatan janin misalnya tengah
              mengisap jari, menguap ataupun menggerakkan jari kakinya. Di
              samping itu pemeriksaan 4D ini bisa mengenali cacat yang ada lebih
              belia, dengan begitu pengobatan akan lebih terukur. Cacat yang
              bisa dikenali misalnya kelainan plasenta atau mengenali terjadinya
              kehamilan ektopik. Manfaat USG 4 dimensi lainnya adalah keluarga
              dapat memperoleh film janinnya berbentuk CD.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/usg.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "USG Four Dimensi RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
